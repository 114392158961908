<template>
  <header v-if="showBack || showLogout">
      <div>
        <router-link to="/" v-if="showBack">
          <img src="@/assets/images/keyboard-backspace.svg" alt="back" />
        </router-link>
      </div>
      <div v-if="showLogout">
        <a @click="logout()"><img src="@/assets/images/location-exit.svg" alt="logout" /></a>
      </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import router from '../router'

export default {
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  },
  computed: {
    ...mapState(['userProfile']),
    showLogout() {
      return Object.keys(this.userProfile).length >= 1 && !this.$route.path.includes('projector');
    },
    showBack() {
      return this.$route.path.includes('results');
    },
  }
}
</script>
