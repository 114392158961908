import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyBue0Txqierkw0jQWDn3HL2dr5ZqUGUGb4",
  authDomain: "chbd-17194.firebaseapp.com",
  databaseURL: "https://chbd-17194-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "chbd-17194",
  storageBucket: "chbd-17194.appspot.com",
  messagingSenderId: "789916937406",
  appId: "1:789916937406:web:b47dfa9f93cd09434142ef",
  measurementId: "G-N67EVQWYWC"
};
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const settingsCollection = db.collection('settings')
const feedbacksCollection = db.collection('feedbacks');

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  postsCollection,
  settingsCollection,
  feedbacksCollection,
}
