<template>
  <div id="dashboard">
    <section v-if="!settings.eventStart && !showFeedbackModal" class="event-description">
      <div>
        <div class="main-image">
          <img src="@/assets/images/main.svg" />
        </div>
        <div class="info-text" v-if="!settings.ended">
          <h2 v-if="!isResults">Привет, {{ userProfile.name }}!</h2>
          <h4>Ожидайте, событие скоро начнется!</h4>
          <div class="parners">
            <ul>
              <li>
                <a href="https://familiae.ru" target="_blank">
                  <img src="@/assets/images/familia-partner.png">
                </a>
              </li>
              <li>
                <a href="https://a2seven.ru" target="_blank">
                  <img src="@/assets/images/a2seven-partner.png">
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="info-text" v-if="settings.ended">
          <h2 v-if="!isResults">Событие окончено</h2>
          <h4>Поделись своим мнением о мероприятии!</h4>
          <div class="add-history" v-if="settings.ended && !feedbacks.length">
            <button @click="openFeedback()" class="button">Оставить отзыв</button>
          </div>
          <div class="add-history" v-if="settings.ended && feedbacks.length">
            <button @click="openFeedback()" class="button">обновить отзыв</button>
          </div>
        </div>
        <div class="info-social" v-if="!isResults" :style="{ 'margin-top': settings.ended ? '2rem' : '2rem' }">
          <p>Подпишитесь на социальные сети наших партенров, там много чего интересного!</p>
          <div class="social-row">
            <a class="button flex" target="_blank" href="https://www.instagram.com/familiae_bc_">
              <img src="@/assets/images/instagram.svg">
              <span>Business Club Familiae</span>
            </a>
            <a class="button flex" target="_blank" href="https://www.instagram.com/a2seven/">
              <img src="@/assets/images/instagram.svg">
              <span>A2SEVEN</span>
            </a>
            <a class="button flex" target="_blank" href="https://vk.com/familiae">
              <img src="@/assets/images/vk.svg">
              <span>Business Club Familiae</span>
            </a>
            <a class="button flex" target="_blank" href="https://vk.com/a2seven">
              <img src="@/assets/images/vk.svg">
              <span>A2SEVEN</span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section v-if="settings.eventStart && !showPostModal && !showFeedbackModal" :class="{ 'projector-section': isResults }">
        <div v-if="settings.activeSpeaker !== null" class="main-image" :class="{ 'projector-image': isResults }">
          <div v-if="!isResults">
            <img class="in-progress-logo" src="@/assets/images/main.svg" />
          </div>
          <img class="speaker-img" :src="getSpeakerImage()" />
        </div>
        <div
            class="info-text"
            :class="{ 'projector-text': isResults }"
            v-if="settings.activeSpeaker !== null && (settings.canAddHistory && !getActivePost.length || !settings.canAddHistory && !settings.showResponses)"
        >
          <h2>{{activeSpeaker.name}}</h2>
          <div class="resident">
            <h5>
              <a style="color:#F4B400; text-decoration: underline" target="_blank" href="https://familiae.ru">
                Резидент бизнес-клуба
              </a>
            </h5>
            <a style="color:#F4B400; text-decoration: underline" target="_blank" href="https://familiae.ru">
             <img src="@/assets/images/familia-partner.png">
            </a>
          </div>
          <h4>{{activeSpeaker.company}}</h4>
        </div>
        <div v-if="!isResults">
          <div class="info-text" v-if="!settings.canAddHistory && !settings.showResponses">
            <p>История еще не закончилась, как только спикер завершит выступление можно будет написать свою версию продолжения</p>
          </div>
          <div class="info-text" v-if="!settings.canAddHistory && settings.showResponses">
            <p>Время вышло, больше нельзя написать свою версию</p>
            <button class="button" @click="viewResults()">смотреть все версии</button>
          </div>
          <div class="info-text" v-if="getActivePost.length === 0 && settings.canAddHistory && settings.activeSpeaker !== null">
            <button @click="viewPost()" class="button">Предложить версию</button>
          </div>
          <div class="info-text" v-if="getActivePost.length > 0 && settings.canAddHistory && settings.activeSpeaker !== null">
            <h2>Спасибо!</h2>
            <p class="history-done">Ваша версия была добавлена</p>
            <button @click="viewPost()" class="button">обновить версию</button>
            <button class="button black-button" @click="viewResults()">смотреть все версии</button>
          </div>
      </div>
    </section>

    <section v-if="showFeedbackModal && settings.ended">
      <div>
        <img class="in-progress-logo write-post-logo" src="@/assets/images/main.svg" />
      </div>
      <div class="write-post-header">
        <span>Вам понравилось мероприятие?</span>
        <a @click="showFeedbackModal = false"><img src="@/assets/images/close.svg" /></a>
      </div>
      <div v-if="feedbacks.length">
        <form v-for="post in feedbacks" :key="post.id" @submit.prevent>
          <textarea class="post-input" v-model.trim="post.content"></textarea>
          <button @click="updateFeedback(post)" :disabled="post.content === ''" class="button">Отправить отзыв</button>
        </form>
      </div>
      <div v-if="!feedbacks.length">
        <form @submit.prevent>
          <textarea class="post-input" v-model.trim="feedback.content"></textarea>
          <button @click="createFeedback()" :disabled="feedback.content === ''" class="button">Отправить отзыв</button>
        </form>
      </div>
    </section>

    <section v-if="showPostModal && settings.canAddHistory && settings.activeSpeaker !== null">
      <div>
        <img class="in-progress-logo write-post-logo" src="@/assets/images/main.svg" />
      </div>
      <div class="write-post-header">
        <span>Че потом?</span>
        <a @click="closePostModal()"><img src="@/assets/images/close.svg" /></a>
      </div>
      <div v-if="getActivePost.length && getActivePost[0].speaker == settings.activeSpeaker">
        <form v-for="post in getActivePost" :key="post.id" @submit.prevent>
          <textarea class="post-input" v-model.trim="post.content"></textarea>
          <button @click="updatePost(post)" :disabled="post.content === '' || !addVersionMode" class="button">отправить версию</button>
        </form>
      </div>
      <div v-if="!getActivePost.length || getActivePost[0].speaker !== settings.activeSpeaker">
        <form @submit.prevent>
          <textarea class="post-input" v-model.trim="post.content"></textarea>
          <button @click="createPost()" :disabled="post.content === '' || !addVersionMode" class="button">отправить версию</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      post: {
        content: ''
      },
      feedback: {
        content: '',
      },
      speakers: [
        {
          name: 'Антон Репьев',
          image: '1.png',
          company: 'Основатель IT компании A2SEVEN и бизнес-клуба Familiae',
        },
        {
          name: 'Кристина Амбарцумян',
          image: '2.png',
          company: 'Основатель производственной компании «Город детства» и пекарни-кондитерской «Мама испекла»',
        },
        {
          name: 'Андрей Садовой',
          image: '3.png',
          company: 'Владелец бренда Richmark и основатель производства автомобильных аксессуаров',
        },
      ],
      showPostModal: false,
      showFeedbackModal: false,
    }
  },
  props: ['isResults'],
  computed: {
    ...mapState(['userProfile', 'posts', 'settings', 'feedbacks']),
    getActivePost(){
      return this.posts.filter(res => res.speaker === this.settings.activeSpeaker);
    },
    activeSpeaker() {
      if (this.settings.activeSpeaker !== null && this.settings.activeSpeaker < this.speakers.length) {
        return this.speakers[this.settings.activeSpeaker];
      } // @todo need to add case when no image

      return {};
    },
    addVersionMode() {
      return this.settings.canAddHistory && this.settings.activeSpeaker !== null;
    }
  },
  methods: {
    getSpeakerImage(){
      if (this.settings.activeSpeaker !== null && this.settings.activeSpeaker < this.speakers.length) {
        return require(`@/assets/images/${this.speakers[this.settings.activeSpeaker]?.image}`);
      } // @todo need to add case when no image
    },
    createPost() {
      this.$store.dispatch('createPost', {
        content: this.post.content,
        speaker: this.settings.activeSpeaker
      });
      this.closePostModal();
      this.post.content = '';
    },
    updatePost(post) {
      this.$store.dispatch('updatePost', { post: post });
      this.closePostModal();
      this.post.content = '';
    },
    async viewPost() {
      this.showPostModal = true;
      document.getElementById('app').scroll(0, 0);
    },
    closePostModal() {
      this.showPostModal = false;
    },
    viewResults() {
      this.$router.push('/results');
    },
    createFeedback() {
      this.$store.dispatch('createFeedback', {
        content: this.feedback.content,
      });
      this.showFeedbackModal = false;
    },
    updateFeedback(post) {
      this.$store.dispatch('updateFeedback', { post: post });
      this.showFeedbackModal = false;
    },
    openFeedback() {
      this.showFeedbackModal = true;
      document.getElementById('app').scroll(0, 0);
    }
  },
  mounted() {
    document.scrollingElement.scroll(0, 0);
  },
  watch: {
    settings(n, o) {
      if (o.ended && !n.ended) {
        this.showFeedbackModal = false;
      }

      if (o.canAddHistory && !n.canAddHistory) {
        this.showPostModal = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 40px;
    }
  }

  .resident {
    display: flex;
    flex-direction: column;
    img {
      width: 200px;
    }
  }

  .parners {
    ul {
      list-style: none;
      padding: 0;
      li {
        &:last-child {
          margin-top: 10px;
        }
        img {
          width: 200px;
        }
      }
    }
  }

  .speaker-img {
    border: 4px solid #F4B400;
    border-radius: 50%;
  }
</style>
