import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router'

Vue.use(Vuex)

// realtime firebase
fb.settingsCollection.onSnapshot(snapshot => {
  
  let settings = getDefaultSettings();

  snapshot.forEach(doc => {
    let d = doc.data();
    settings = Object.assign(settings, d);
  });

  store.commit('setSettings', settings);
})

const getDefaultSettings = () => {
  return {
    activeSpeaker: null,
    canAddHistory: false,
    eventStart: false,
    pause: false,
    responseTimeSeconds: 0,
    showResponses: false,
    ended: false,
  };
};

const store = new Vuex.Store({
  state: {
    userProfile: {},
    settings: getDefaultSettings(),
    posts: [],
    feedbacks: [],
    postsForSpeaker: [],
    loading: false,
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setPosts(state, val) {
      state.posts = val
    },
    setFeedbacks(state, val) {
      state.feedbacks = val
    },
    setSettings(state, val) {
      state.settings = val;
    },
    setSpeakerPosts(state, val) {
      state.postsForSpeaker = val;
      state.loading = false;
    },
    setLoading(state, val) {
      state.loading = val;
    }
  },
  actions: {
    getPostsForSpeaker({ commit }) {
      fb.postsCollection
          .orderBy('createdOn', 'asc')
          .onSnapshot(snapshot => {
            let postsArray = [];

            snapshot.forEach((doc) => {
              let post = doc.data();
              post.id = doc.id;
              postsArray.push(post)
            })

            commit('setSpeakerPosts', postsArray)
          });
    },
    async getPosts({ commit }, userProfile) {
      fb.postsCollection
        .where('userId', '==', userProfile.id)
        .orderBy('createdOn', 'desc')
        .onSnapshot(snapshot => {
          let postsArray = []
          
          snapshot.forEach(doc => {
            let post = doc.data()
            post.id = doc.id
        
            postsArray.push(post)
          })
      
          commit('setPosts', postsArray)
        })
    },
    async login({ dispatch }, form) {
      try {
        const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
        dispatch('fetchUserProfile', user);
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          alert('Вы ввели неверный пароль.')
        }

        if (error.code === 'auth/user-not-found') {
          alert('Такого пользователя не существует.')
        }

        if (error.code === 'auth/invalid-email') {
          alert('Вы ввели некоректные данные (а может и не ввели)')
        }
      }
    },
    async signup({ dispatch }, form) {
      try {
        const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
        // create user object in userCollections
        await fb.usersCollection.doc(user.uid).set({
          name: form.email.match(/^(.+)@/)[1],
          id: user.uid,
        });

        // fetch user profile and set in state
        dispatch('fetchUserProfile', user)
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          alert('Такой пользователь уже существует')
        }

        if (error.code === 'auth/invalid-email') {
          alert('Вы ввели некоректные данные (а может и не ввели)');
        }

        if (error.code === 'auth/weak-password') {
          alert('Пароль должен содержать не менее 6 символов');
        }
      }

    },
    async fetchUserProfile({ commit, dispatch, state }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()
      console.log('::::::>>>>', userProfile.data())
      // set user profile in state
      commit('setUserProfile', userProfile.data())
      dispatch('getPosts', userProfile.data())
      dispatch('getFeedbacks', userProfile.data())
      dispatch('getPostsForSpeaker');

      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})

      localStorage.setItem('LOGOUT', 'Y');

      // redirect to login view
      router.push('/login')
    },
    async createPost({ state, commit }, post) {
      // create post in firebase
      await fb.postsCollection.add({
        createdOn: new Date(),
        content: post.content,
        speaker: post.speaker,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name,
        mc: false,
      })
    },
    async updateSettings({ state, commit }, data) {
      if (state.userProfile.role === 'admin') {
        await  fb.settingsCollection.doc('ORa4UfSA5EGxwVGncFtN').update({
          ...state.settings,
          ...data,
        });
      }
    },
    async updatePost({ commit, state }, { post }){
      commit('setLoading', true);
      const userId = fb.auth.currentUser.uid;
      const postRef = await fb.postsCollection.doc(post.id);
      const postDocument = await postRef.get();
      // if post is mine
      if (userId === postDocument.data().userId || this.state.userProfile.role === 'admin') {
        postRef.update({
          content: post.content,
          mc: post.mc,
        });
      }
      
    },
    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid
      // update user object
      const userRef = await fb.usersCollection.doc(userId).update({
        name: user.name,
        title: user.title
      })

      dispatch('fetchUserProfile', { uid: userId })

      // update all posts by user
      const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
      postDocs.forEach(doc => {
        fb.postsCollection.doc(doc.id).update({
          userName: user.name
        })
      });
    },
    async createFeedback({ state }, post) {
      await fb.feedbacksCollection.add({
        content: post.content,
        userId: fb.auth.currentUser.uid,
        user: state.userProfile.name,
      })
    },
    async updateFeedback({ state }, { post }) {
      const userId = fb.auth.currentUser.uid;
      const postRef = await fb.feedbacksCollection.doc(post.id);
      const postDocument = await postRef.get();
      // if post is mine
      if (userId === postDocument.data().userId || this.state.userProfile.role === 'admin') {
        postRef.update({
          content: post.content,
        });
      }
    },
    async getFeedbacks({ commit }, userProfile) {
      fb.feedbacksCollection
          .where('userId', '==', userProfile.id)
          .onSnapshot(snapshot => {
            let postsArray = []

            snapshot.forEach(doc => {
              let post = doc.data()
              post.id = doc.id

              postsArray.push(post)
            })

            // console.log(postsArray);

            commit('setFeedbacks', postsArray)
          })
    }
  }
})

export default store
